import React from "react";
import { createRoot } from "react-dom/client";
import { installWidgetResizeWatcher } from "@amzn/paragon-context/lib/ParagonWidgetDataContext/installWidgetResizeWatcher";

import { DocumentTranslationWidget } from "./widgets/DocumentTranslationWidget/DocumentTranslationWidget";
import { ParagonContextConsumerEl } from "@amzn/paragon-ui-react";
import "@amzn/paragon-ui-react/lib/styles.css";
import "@amzn/katal-components/styles.css";
import "@amzn/document-analysis-widget-assets/dist/pdfworker/pdf.worker.mjs";

import { AppInitWrapper } from "./components/AppInitWrapper";

installWidgetResizeWatcher();

const root = createRoot(document.getElementById("root")!);

root.render(
    <ParagonContextConsumerEl>
        <AppInitWrapper>
            <DocumentTranslationWidget />
        </AppInitWrapper>
    </ParagonContextConsumerEl>
);
